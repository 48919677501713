<template>
	<form
		class="main-wrapper mt-4"
		:class="`type-${type}`"
		@submit.prevent="handleSubmit"
	>
		<h4 class="mb-4">Label info</h4>

		<ProductLabelEditImageForm
			:class="`type-${type}`"
			:is-valid-image="isValidImage"
			:error-image-message="errorImageMessage"
			:preview-image="previewImage"
			:is-primary="isPrimary"
			:type="type"
			class="product-label-image-form"
			@onChange="handleFileChange"
		/>

		<CInput
			v-model.trim="$v.name.$model"
			:is-valid="!$v.name.$error && null"
			label="Label name"
			placeholder="Type the label name here"
			invalid-feedback="Field Length: 1 - 255 characters"
			data-test-id="label-name-product"
			class="product-label-name"
			@change="$v.name.$touch()"
		/>
		<div class="form-group form-group-switch">
			<label class="toggle-label" for="status">Status</label>
			<CSwitch
				id="status"
				:checked.sync="status"
				variant="3d"
				size="sm"
				class="switch-custom"
				color="success"
			/>
			<span>{{ status ? 'Active' : 'Inactive' }}</span>
		</div>

		<hr>

		<ProductLabelEditExpire
			:start-date.sync="$v.expiredStartDate.$model"
			:start-time.sync="$v.expiredStartTime.$model"
			:end-date.sync="$v.expiredEndDate.$model"
			:end-time.sync="$v.expiredEndTime.$model"
			:v="$v"
			@update:start-date="handleChangedStartDate"
			@update:start-time="handleChangedStartTime"
			@update:end-date="$v.expiredEndDate.$reset"
			@update:end-time="$v.expiredEndTime.$reset"
		/>

		<hr>

		<BaseActionPanelStickyFooter
			:disabled-confirm="create.isLoading"
			content-class="main-wrapper"
			data-test-id="create-type-product-labels"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ProductLabels'})"
		/>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ProductLabelEditImageForm from '@/components/ProductLabelEditImageForm.vue';
import ProductLabelEditExpire from '@/components/ProductLabelEditExpire.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';
import { timeFormat, afterDateTime } from '../assets/js/validators';
import { convertDateTimeToUTC, validateImageFileName } from '../assets/js/helpers';

const TWO_MB = 1024 * 1024 * 2;

export default {
	name: 'ProductLabelCreateForm',

	validations: {
		name: {
			required,
			maxLength: maxLength(255),
		},
		expiredStartDate: {
			requiredIf: requiredIf((vm) => vm.expiredStartTime),
		},
		expiredStartTime: {
			requiredIf: requiredIf((vm) => vm.expiredStartDate),
			timeFormat,
		},
		expiredEndDate: {
			requiredIf: requiredIf((vm) => vm.expiredEndTime),
		},
		expiredEndTime: {
			requiredIf: requiredIf((vm) => vm.expiredEndDate),
			timeFormat,
			after: afterDateTime({
				fromDateKey: 'expiredStartDate',
				fromTimeKey: 'expiredStartTime',
				toDateKey: 'expiredEndDate',
				toTimeKey: 'expiredEndTime',
			}),
		},
	},
	components: {
		ProductLabelEditImageForm,
		ProductLabelEditExpire,
	},
	mixins: [validationMixin],
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},
	data() {
		return {
			file: null,
			name: null,
			status: true,
			expiredStartDate: null,
			expiredStartTime: null,
			expiredEndDate: null,
			expiredEndTime: null,
			previewImage: null,
			isValidImage: true,
			PRODUCT_LABEL_TYPES,
			maxFileSize: TWO_MB,
			errorImageMessage: null,
		};
	},
	computed: {
		...mapState('productLabels', {
			create: 'create',
		}),

		isPrimary() {
			return this.type === PRODUCT_LABEL_TYPES.primary;
		},
		expiredStartAt() {
			if (this.expiredStartDate && this.expiredStartTime && !this.$v.expiredStartTime.$invalid) {
				return convertDateTimeToUTC(this.expiredStartDate, this.expiredStartTime);
			}
			return '';
		},
		expiredEndAt() {
			if (this.expiredEndDate && this.expiredEndTime && !this.$v.expiredEndTime.$invalid) {
				return convertDateTimeToUTC(this.expiredEndDate, this.expiredEndTime);
			}
			return '';
		},
	},
	methods: {
		...mapActions({
			postProductLabel: 'productLabels/postProductLabel',
			showToast: 'toast/showToast',
		}),

		handleFileChange(e) {
			const file = e.target.files[0];

			const errorMaxFileSize = file.size > this.maxFileSize;
			const errorFileName = !validateImageFileName(file.name);

			if (errorMaxFileSize || errorFileName) {
				this.isValidImage = false;
				this.errorImageMessage = errorMaxFileSize ? this.$t('global.error.maxFileSize', { size: 2 }) : this.$t('components.modalMediaLibrary.error.uploadValidationImageFileName');
			} else {
				this.file = file;
				this.previewImage = URL.createObjectURL(file);
				this.isValidImage = true;
			}
		},
		async handleSubmit() {
			this.$v.$touch();

			if (!this.file) {
				this.isValidImage = false;
				this.errorImageMessage = this.$t('global.error.required');
			}

			if (!this.$v.$invalid && this.isValidImage) {
				const formData = new FormData();
				formData.append('name', this.name);
				formData.append('file', this.file);
				formData.append('type', this.type);
				formData.append('is_active', this.status ? 1 : 0);
				formData.append('start_at', this.expiredStartAt);
				formData.append('end_at', this.expiredEndAt);

				let toast = {
					content: this.$t('global.successMessage'),
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				};

				try {
					await this.postProductLabel(formData);

					this.$router.push({
						name: 'ProductLabelList',
						query: { type: this.type },
					});
				} catch (error) {
					// Handle error code 'invalid_image_name'
					toast = {
						content: error?.response?.data?.message ?? this.$t('global.errorMessageTitleEdit'),
						header: this.$t('global.errorMessage'),
						type: 'danger',
					};
				}

				this.showToast(toast);
			}
		},

		handleChangedStartDate() {
			this.$v.expiredStartDate.$reset();
			this.$v.expiredEndTime.$reset();
		},

		handleChangedStartTime() {
			this.$v.expiredStartTime.$reset();
			this.$v.expiredEndTime.$reset();
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.product-label-image-form,
	.product-label-name {
		margin-bottom: rem(32);
	}

	.product-label-image-form {
		padding: 0;
	}
</style>
