<template>
	<ProductBadgeLabelCreateForm v-if="type === PRODUCT_LABEL_TYPES.badge" :type="type" />
	<ProductLabelCreateForm v-else :type="type" />
</template>

<script>
import { mapActions } from 'vuex';
import ProductLabelCreateForm from '@/components/ProductLabelCreateForm.vue';
import ProductBadgeLabelCreateForm from '@/components/ProductBadgeLabelCreateForm.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelCreate',

	components: {
		ProductLabelCreateForm,
		ProductBadgeLabelCreateForm,
	},

	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},

	computed: {
		type() {
			switch (this.$route.query.type) {
				case PRODUCT_LABEL_TYPES.primary:
					return PRODUCT_LABEL_TYPES.primary;
				case PRODUCT_LABEL_TYPES.frame:
					return PRODUCT_LABEL_TYPES.frame;
				case PRODUCT_LABEL_TYPES.secondary:
					return PRODUCT_LABEL_TYPES.secondary;
				case PRODUCT_LABEL_TYPES.badge:
					return PRODUCT_LABEL_TYPES.badge;
				default:
					return null;
			}
		},
	},
	created() {
		this.setPageTitle({
			title: `Create new ${this.type} label`,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
		}),
	},
};
</script>
