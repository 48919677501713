<template>
	<form
		class="main-wrapper mt-4"
		:class="`type-${type}`"
		@submit.prevent="handleSubmit"
	>
		<h4 class="mb-4">Label info</h4>

		<CInput
			v-model.trim="$v.name.$model"
			:is-valid="!$v.name.$error && null"
			label="Label name"
			placeholder="Type the label name here"
			invalid-feedback="Field Length: 1 - 255 characters"
			data-test-id="label-name-product"
			class="product-label-name"
			@change="$v.name.$touch()"
		/>

		<BaseDropDown
			v-model="$v.fontColor.$model"
			:is-valid="!$v.fontColor.$error && null"
			:options="BADGE_LABEL_OPTIONS"
			:searchable="false"
			:allow-empty="false"
			label="label"
			track-by="value"
			class="select-custom"
			label-drop-down="Font Color"
			invalid-feedback="Required Field"
			placeholder="Select Font Color"
			is-custom-color-label-option
			@input="handleSelectFontColor"
		/>

		<div class="badge-input-wrapper">
			<CInput
				v-model.trim="$v.titleEN.$model"
				:is-valid="!$v.titleEN.$error && null"
				label="Label title (EN)"
				placeholder="Type the label name here"
				invalid-feedback="Field Length: 1 - 255 characters"
				data-test-id="label-name-product"
				class="product-badge-label-name"
				@change="$v.titleEN.$touch()"
			/>
			<span class="example-text">Example : <ProductLabelBadge :label="titleEN" :color="fontColorValue" /></span>
		</div>
		<div class="badge-input-wrapper">
			<CInput
				v-model.trim="$v.titleTH.$model"
				:is-valid="!$v.titleTH.$error && null"
				label="Label title (TH)"
				placeholder="Type the label name here"
				invalid-feedback="Field Length: 1 - 255 characters"
				data-test-id="label-name-product"
				class="product-badge-label-name"
				@change="$v.titleTH.$touch()"
			/>
			<span class="example-text">Example : <ProductLabelBadge :label="titleTH" :color="fontColorValue" /></span>
		</div>

		<div class="form-group form-group-switch">
			<label class="toggle-label" for="status">Status</label>
			<CSwitch
				id="status"
				:checked.sync="status"
				variant="3d"
				size="sm"
				class="switch-custom"
				color="success"
			/>
			<span>{{ status ? 'Active' : 'Inactive' }}</span>
		</div>
		<!--hide ไว้ก่อนจาก ticket S7APP-1207-->
		<!-- <div>
			<div class="form-group form-group-switch">
				<label class="toggle-label" for="lob-visibility">LOB visibility</label>
				<CSwitch
					id="lob-visibility"
					:checked.sync="lobVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
				/>
				<span>{{ lobVisibility ? 'On' : 'Off' }}</span>
			</div>

			<div class="form-group form-group-switch">
				<label class="toggle-label" for="plp-visibility">PLP visibility</label>
				<CSwitch
					id="plp-visibility"
					:checked.sync="plpVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
				/>
				<span>{{ plpVisibility ? 'On' : 'Off' }}</span>
			</div>

			<div class="form-group form-group-switch">
				<label class="toggle-label" for="product-family-visibility">Product family visibility</label>
				<CSwitch
					id="product-family-visibility"
					:checked.sync="productFamilyVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
				/>
				<span>{{ productFamilyVisibility ? 'On' : 'Off' }}</span>
			</div>
		</div> -->
		<hr>
		<!--Setting label-->
		<template name="setting-label">
			<h4 class="mb-1">Setting Label</h4>
			<p class="typo-body-2 color-black-45 mb-3">The label will be set as active and inactive follow to selected below. Leave it blank to change label status manually.</p>
			<div class="row">
				<div v-for="(option, index) in settingLabelOptions" :key="index" class="col-md-6">
					<CInputRadio
						name="setting-label"
						:label="option.label"
						:value="option.value"
						:checked="option.value == isSpecialVisible"
						custom
						@update:checked="handleRadioSettingLabel(option.value)"
					/>
				</div>
			</div>
		</template>
		<hr>
		<ProductLabelEditExpire
			:start-date.sync="$v.expiredStartDate.$model"
			:start-time.sync="$v.expiredStartTime.$model"
			:end-date.sync="$v.expiredEndDate.$model"
			:end-time.sync="$v.expiredEndTime.$model"
			:v="$v"
			@update:start-date="handleChangedStartDate"
			@update:start-time="handleChangedStartTime"
			@update:end-date="$v.expiredEndDate.$reset"
			@update:end-time="$v.expiredEndTime.$reset"
		/>

		<hr>

		<BaseActionPanelStickyFooter
			:disabled-confirm="create.isLoading"
			content-class="main-wrapper"
			data-test-id="create-type-product-badge-labels"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ProductLabels'})"
		/>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ProductLabelEditExpire from '@/components/ProductLabelEditExpire.vue';
import ProductLabelBadge from '@/components/ProductLabelBadge.vue';
import { PRODUCT_LABEL_TYPES, BADGE_LABEL_OPTIONS } from '../enums/productLabels';
import { timeFormat, afterDateTime } from '../assets/js/validators';
import { convertDateTimeToUTC } from '../assets/js/helpers';
import { BUNDLE_SETTING_BADGE_OPTIONS } from '../enums/bundleSet';

export default {
	name: 'ProductBadgeLabelCreateForm',

	validations: {
		name: {
			required,
			maxLength: maxLength(255),
		},
		titleEN: {
			required,
			maxLength: maxLength(255),
		},
		titleTH: {
			required,
			maxLength: maxLength(255),
		},
		fontColor: {
			required,
		},
		expiredStartDate: {
			requiredIf: requiredIf((vm) => vm.expiredStartTime),
		},
		expiredStartTime: {
			requiredIf: requiredIf((vm) => vm.expiredStartDate),
			timeFormat,
		},
		expiredEndDate: {
			requiredIf: requiredIf((vm) => vm.expiredEndTime),
		},
		expiredEndTime: {
			requiredIf: requiredIf((vm) => vm.expiredEndDate),
			timeFormat,
			after: afterDateTime({
				fromDateKey: 'expiredStartDate',
				fromTimeKey: 'expiredStartTime',
				toDateKey: 'expiredEndDate',
				toTimeKey: 'expiredEndTime',
			}),
		},
	},
	components: {
		ProductLabelEditExpire,
		ProductLabelBadge,
	},
	mixins: [validationMixin],
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
	},
	data() {
		return {
			fontColor: null,
			titleEN: null,
			titleTH: null,
			status: true,
			expiredStartDate: null,
			expiredStartTime: null,
			expiredEndDate: null,
			expiredEndTime: null,
			PRODUCT_LABEL_TYPES,
			BADGE_LABEL_OPTIONS,
			errorImageMessage: null,
			lobVisibility: false,
			plpVisibility: false,
			productFamilyVisibility: false,
			isSpecialVisible: true,
			settingLabelOptions: BUNDLE_SETTING_BADGE_OPTIONS,
		};
	},
	computed: {
		...mapState('productLabels', {
			create: 'create',
		}),

		fontColorValue() {
			return this.fontColor && this.fontColor.value;
		},

		expiredStartAt() {
			if (this.expiredStartDate && this.expiredStartTime && !this.$v.expiredStartTime.$invalid) {
				return convertDateTimeToUTC(this.expiredStartDate, this.expiredStartTime);
			}
			return '';
		},

		expiredEndAt() {
			if (this.expiredEndDate && this.expiredEndTime && !this.$v.expiredEndTime.$invalid) {
				return convertDateTimeToUTC(this.expiredEndDate, this.expiredEndTime);
			}
			return '';
		},
	},
	methods: {
		...mapActions({
			postProductLabel: 'productLabels/postProductLabel',
			showToast: 'toast/showToast',
		}),

		async handleSubmit() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				const formData = new FormData();
				formData.append('name', this.name);
				formData.append('title_en', this.titleEN);
				formData.append('title_th', this.titleTH);
				formData.append('font_color', this.fontColor?.value);
				formData.append('type', this.type);
				formData.append('is_active', this.status ? 1 : 0);
				formData.append('start_at', this.expiredStartAt);
				formData.append('end_at', this.expiredEndAt);

				formData.append('is_lob_visible', this.lobVisibility ? 1 : 0);
				formData.append('is_plp_visible', this.plpVisibility ? 1 : 0);
				formData.append('is_product_family_visible', this.productFamilyVisibility ? 1 : 0);
				formData.append('is_special_visible', this.isSpecialVisible ? 1 : 0);

				let toast = {
					content: this.$t('global.successMessage'),
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				};

				try {
					await this.postProductLabel(formData);

					this.$router.push({
						name: 'ProductLabelList',
						query: { type: this.type },
					});
				} catch (error) {
					// Handle error code 'invalid_image_name'
					toast = {
						content: error?.response?.data?.message ?? this.$t('global.errorMessageTitleEdit'),
						header: this.$t('global.errorMessage'),
						type: 'danger',
					};
				}

				this.showToast(toast);
			}
		},

		handleChangedStartDate() {
			this.$v.expiredStartDate.$reset();
			this.$v.expiredEndTime.$reset();
		},

		handleChangedStartTime() {
			this.$v.expiredStartTime.$reset();
			this.$v.expiredEndTime.$reset();
		},

		handleSelectFontColor(value) {
			this.fontColor = value;
		},

		handleRadioSettingLabel(value) {
			this.isSpecialVisible = value;
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.product-badge-label-image-form,
	.badge-input-wrapper {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: rem(32);

		.form-group {
			margin-bottom: rem(8);
		}

		.example-text {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.product-badge-label-image-form {
		padding: 0;
	}
</style>
